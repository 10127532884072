<template>
  <div>
    <template v-if="getComponent(widgetDetails.widgetCategoryID)">
      <component
        :is="getComponent(widgetDetails.widgetCategoryID).component"
        :widgetDetails="widgetDetails"
        :data="widgetDetails[getComponent(widgetDetails.widgetCategoryID).name]"
        :chartConfigurations="widgetDetails.chartConfigurations"
        :widgetTblConfigurations="widgetDetails.widgetTblConfigurations"
        :tenantId="widgetDetails.tenantID"
        :culture="widgetDetails.culture"
        :defaultCulture="defaultCulture"
        :formSubmitted="formSubmitted"
        :isEditPage="isEditPage"
      ></component>
    </template>
    <b-form-row>
      <b-col>
        <b-form-group
          :class="{
            'is-invalid': !widgetDetails.dashboardTxt && widgetDetails.widgetCategoryID == 1 && formSubmitted,
          }"
        >
          <p id="dashboard-text-label">
            Dashboard text
            <span v-if="widgetDetails.widgetCategoryID != 1">(Optional)</span>
          </p>
          <editor id="description" :api-key="tinyEditorOptions.apiKey" :init="tinyEditorOptions.initConfig" v-model="widgetDetails.dashboardTxt" />
          <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.dashboardTxt && widgetDetails.widgetCategoryID == 1 && formSubmitted"
            >Dashboard content required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox id="use-dashboard-text-check" name="use-dashboard-text-check" @change="updateExpandedViewText">
            Use dashboard text for expanded view
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group
          :class="{
            'is-invalid': !widgetDetails.expandedVwTxt && widgetDetails.widgetCategoryID == 1 && formSubmitted,
          }"
        >
          <p id="expanded-view-text-label">
            Expanded view text
            <span v-if="widgetDetails.widgetCategoryID != 1">(Optional)</span>
          </p>
          <editor id="expanded-view-text" :api-key="tinyEditorOptions.apiKey" :init="getTinyMCEConfig" v-model="widgetDetails.expandedVwTxt" />
          <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.expandedVwTxt && widgetDetails.widgetCategoryID == 1 && formSubmitted"
            >Expanded view content required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import { TinyEditorOptions, TinyMCECustomPanelOptions, WidgetTypesSettings } from '../../../utilities/constants'
export default {
  name: 'Content',
  props: ['widgetDetails', 'isEditPage', 'formSubmitted', 'defaultCulture'],
  components: {
    editor: Editor,
    CalendarWidget: () => import('./CalendarWidget.vue'),
    NewsWidget: () => import('./NewsWidget.vue'),
    LocationWidget: () => import('./LocationWidget.vue'),
    IntegrationWidget: () => import('./IntegrationWidget.vue'),
    FormBuilderWidget: () => import('./FormBuilderWidget.vue'),
    RecreationWidget: () => import('./RecreationWidget.vue'),
  },
  data() {
    return {
      tinyEditorOptions: TinyEditorOptions,
      widgetTypesSettings: WidgetTypesSettings,
    }
  },
  computed: {
    getTinyMCEConfig() {
      return { ...TinyEditorOptions.initConfig, ...TinyMCECustomPanelOptions }
    },
    getComponent() {
      return (id) => {
        const widget = this.widgetTypesSettings.find((widget) => widget.widgetCategoryID === id)
        return widget ? (widget.widgetCategoryID !== 1 ? widget : false) : false
      }
    },
  },
  methods: {
    updateExpandedViewText(event) {
      this.widgetDetails.expandedVwTxt = event ? this.widgetDetails.dashboardTxt : ''
    },
  },
}
</script>
<style lang="sass" scoped></style>
